import { useEffect, useState } from 'react'
import { List, ListItem, Paper } from '@mui/material'
import { DateTime } from 'luxon'
import { usePDF } from 'react-to-pdf'
import { ThemeProvider } from '@mui/material/styles'
import theme from '../../theme'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Copyright from '../../components/Copyright/Copyright'
import { useSearchParams } from 'react-router-dom'
import { getHDs, postValidation } from 'adapters/backend/hourDeclaration'
import { capitalize } from '../../utils/tools'
import { GradientButton } from 'components/Button/Button.style'
import { getProductLabel, Pricing } from 'utils/labels'
import { HDDetailsFooter } from 'components/HDDetails/HDDetailsFooter'
import { Loading } from 'components/Loading/Loading'
import { NotFound } from 'components/NotFound/NotFound'

const makeEmailBody = (pricings: Pricing[]) => {
  let html = `Bonjour,%0DVous contestez le rapport de votre intervenant.%0DNous vous invitons à détailler sous ce texte les jours, nature des intervention et quantités concernés par la modification.%0D%0D`

  for (const pricing of pricings) {
    html =
      html +
      `${capitalize(
        DateTime.fromISO(pricing.slotStartDate)
          .setLocale('fr')
          .toFormat('dd/MM')
      )} : ${getProductLabel(
        pricing.product,
        pricing.surchargeType,
        pricing.nightType
      )} : ${
        pricing.productType === 'HORAIRE' ? `${pricing.hoursBilled}` : `1`
      } %0D`
  }

  html =
    html +
    `%0DDès réception, nous apporterons les modifications et vous reverrons la facture correspondante. Le prélèvement aura lieu 24h après réception de la facture.%0DCordialement,%0DVotre équipe support%0D--------------------------%0DVos modifications :`

  return html
}

export default function FamilyHDDetails() {
  const [searchParams] = useSearchParams()
  const [pricings, setPricings] = useState<Pricing[]>([])
  const [mission, setMission] = useState<any>()
  const [allValidated, setAllValidated] = useState<boolean>(false)

  useEffect(() => {
    const missionId = searchParams.get('missionId')
    const date = searchParams.get('date')

    if (!missionId || !date) {
      return
    }

    getHDs(missionId, date).then(({ mission, prices, allValidated }) => {
      setMission(mission)
      setPricings(prices)
      setAllValidated(allValidated)
    })
  }, [])

  const onValidate = () => {
    const missionId = searchParams.get('missionId')
    const date = searchParams.get('date')

    if (!missionId || !date) {
      return
    }

    postValidation(missionId, date).then(() => {
      window.location.href = '/confirmer-famille'
    })
  }

  const { toPDF, targetRef } = usePDF({ filename: 'declaration.pdf' })

  if (!mission) {
    return <Loading />
  }

  if (pricings.length === 0) {
    return <NotFound />
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <Box
          component="main"
          sx={{
            backgroundColor: currentTheme =>
              currentTheme.palette.mode === 'light'
                ? currentTheme.palette.grey[100]
                : currentTheme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            paddingTop: '66px'
          }}
        >
          <Container maxWidth="lg" sx={{ mt: 0, mb: 4 }}>
            <Grid container spacing={3} paddingBottom={8}>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <img
                  src="/img/logo-click-and-care-color.png"
                  style={{
                    display: 'inline-block',
                    width: '350px',
                    textAlign: 'center'
                  }}
                  alt="logo"
                />
                <br />
                <br />
                <Box
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center'
                  }}
                >
                  <Box
                    paddingBottom={2}
                    display={'flex'}
                    justifyContent={'flex-end'}
                  >
                    <GradientButton onClick={() => toPDF()}>
                      Télécharger le PDF
                    </GradientButton>
                  </Box>
                  <Paper
                    ref={targetRef}
                    sx={{
                      p: 4,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }}
                  >
                    {pricings.length === 0 && (
                      <Box>Aucune déclaration pour le mois en cours</Box>
                    )}
                    {mission && (
                      <Box fontSize={'1.3em'} textAlign={'center'} width="100%">
                        <Box
                          textAlign={'center'}
                          color={theme.palette.primary.main}
                          fontWeight="500"
                        >
                          DÉCLARATION HORAIRE{' '}
                          {mission.contractType === 'liberal'
                            ? 'LIBÉRAL'
                            : 'CDI'}{' '}
                          DU MOIS DE{' '}
                          {DateTime.fromISO(pricings[0].slotStartDate)
                            .setLocale('fr')
                            .toFormat('MMMM yyyy')
                            .toUpperCase()}
                        </Box>
                        <Box>
                          déposée par {mission.careGiver.person.firstName}{' '}
                          {mission.careGiver.person.lastName}
                        </Box>
                        <br />
                      </Box>
                    )}
                    <List sx={{ width: '100%' }}>
                      <ListItem
                        key={'labels'}
                        sx={{
                          width: '100%',
                          paddingLeft: 0,
                          borderBottom: '1px solid lightgrey'
                        }}
                      >
                        <Box
                          display={'flex'}
                          width={'100%'}
                          flexDirection={'row'}
                        >
                          <Box flex={0.2} textAlign="center">
                            <b>Date</b>
                          </Box>
                          <Box flex={0.6} textAlign="center">
                            <b>Nature des interventions</b>
                          </Box>
                          <Box flex={0.2} textAlign="center">
                            <b>Quantité</b>
                          </Box>
                        </Box>
                      </ListItem>
                      {pricings.map(pricing => (
                        <ListItem
                          key={JSON.stringify(pricing)}
                          sx={{
                            width: '100%',
                            paddingLeft: 0,
                            borderBottom: '1px solid lightgrey'
                          }}
                        >
                          <Box
                            display={'flex'}
                            width={'100%'}
                            flexDirection={'row'}
                          >
                            <Box
                              flex={0.2}
                              textAlign="center"
                              display={'flex'}
                              flexDirection={'row'}
                            >
                              <Box flex={0.5}>
                                {capitalize(
                                  DateTime.fromISO(pricing.slotStartDate)
                                    .setLocale('fr')
                                    .toFormat('EEEE')
                                )}
                              </Box>
                              <Box flex={0.5}>
                                {capitalize(
                                  DateTime.fromISO(pricing.slotStartDate)
                                    .setLocale('fr')
                                    .toFormat('dd/MM')
                                )}
                              </Box>
                            </Box>
                            <Box flex={0.6} textAlign="center">
                              {getProductLabel(
                                pricing.product,
                                pricing.surchargeType,
                                pricing.nightType
                              )}
                            </Box>
                            <Box flex={0.2} textAlign="center">
                              {pricing.productType === 'HORAIRE'
                                ? `${pricing.hoursBilled}`
                                : `1`}
                            </Box>
                          </Box>
                        </ListItem>
                      ))}
                    </List>
                    <br />
                    <HDDetailsFooter mission={mission} pricings={pricings} />
                  </Paper>
                  {(pricings.length && (
                    <>
                      <Box
                        paddingTop={2}
                        display={'flex'}
                        justifyContent={'flex-end'}
                        width={'100%'}
                        gap={2}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          href={`mailto:facturation@clickandcare.fr?subject=Contestation Déclarations horaire&body=${makeEmailBody(
                            pricings
                          )}`}
                          style={{ color: 'white', borderRadius: '8px' }}
                          disabled={allValidated}
                        >
                          CONTESTER LA DÉCLARATION
                        </Button>
                        <GradientButton
                          onClick={() => onValidate()}
                          disabled={allValidated}
                        >
                          VALIDER LA DÉCLARATION
                        </GradientButton>
                      </Box>
                      {allValidated && (
                        <Box
                          paddingTop={2}
                          display={'flex'}
                          justifyContent={'flex-end'}
                          width={'100%'}
                          gap={2}
                        >
                          Vous avez déjà validé le mois en cours
                        </Box>
                      )}
                    </>
                  )) || <></>}
                </Box>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
