/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  Snackbar,
  Stack,
  Switch,
  TextField,
  Toolbar,
  Typography
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import { useNavigate, useParams } from 'react-router-dom'

import { ThemeProvider, useTheme } from '@emotion/react'
import Nav from 'components/Nav/Nav'
import Copyright from 'components/Copyright/Copyright'
import { getQuoteS3Url } from 'adapters/backend/quote'
import { useFormik } from 'formik'
import {
  CONTRACT_TYPE_FR,
  ContractType,
  FAMILY_SERVICES_FR,
  QUOTE_STATUS
} from 'types/enum'
import { UpdateQuoteDto } from 'types/dto'
import { DatePicker } from '@mui/x-date-pickers'
import { Planning } from 'components/Calendar/template'
import Keycloak from 'keycloak-js'
import Unauthorized from 'components/Unauthorized/Unauthorized'
import {
  useAvailablePipedriveLeads,
  useDuplicateQuoteMutation,
  useGetAllQuotes,
  useGetLeadDetails,
  useNewQuoteMutation,
  useUpdateQuoteMutation,
  useValidateQuoteMutation
} from 'pages/Quote/hook'
import {
  UpdateQuoteForm,
  getEstimateEndDate,
  toUpdateQuoteForm
} from './helpers'
import { QuotePricing } from 'components/Quote/QuotePricing'
import { useScheduleContext } from 'hooks/scheduleContext.hook'
import { servicesListToAutocompleteOptions } from 'utils/enums'
import { DateTime } from 'luxon'
import { useRealScheduleWithEstimates } from 'hooks'

export default function Quote({ keycloak }: { keycloak?: Keycloak }) {
  useEffect(() => {
    if (!keycloak) {
      return
    }

    if (!keycloak.authenticated) {
      window.location.assign(keycloak.createLoginUrl())
    }
  }, [keycloak])

  const theme = useTheme()
  const navigate = useNavigate()
  const [isSubmitting, setIsSumbitting] = useState(false)
  const [displaySavedAlert, setDisplaySavedAlert] = useState(false)

  const { leadId, quoteId } = useParams<{ leadId: string; quoteId?: string }>()
  const { data: availableLeads } = useAvailablePipedriveLeads()
  const { data: leadDetails } = useGetLeadDetails(leadId)

  const [estimateType, setEstimateType] = useState('hebdo')

  const { data: leadQuoteList } = useGetAllQuotes(leadId)
  const validateQuoteMutation = useValidateQuoteMutation(leadId)
  const duplicateQuoteMutation = useDuplicateQuoteMutation(leadId)
  const newQuoteMutation = useNewQuoteMutation(leadId)
  const { isPending: isUpdatePending, mutateAsync: updateQuoteMutation } =
    useUpdateQuoteMutation(leadId)

  const handleLeadSelect = (selectedLeadId: string) => {
    navigate(`/devis/${selectedLeadId}`)
  }

  const handleQuoteSelect = (selectedQuoteId: string) => {
    navigate(`/devis/${leadId}/edit/${selectedQuoteId}`)
  }

  const { scheduleData } = useScheduleContext()

  const openQuoteS3Url = async () => {
    const url = await getQuoteS3Url(selectedQuote?.id as string)

    window.open(url)
  }

  const selectedLead = availableLeads
    ? availableLeads.find(lead => lead.id === leadId)
    : null

  const selectedQuote = leadQuoteList
    ? leadQuoteList.find(quote => quote.id === quoteId)
    : null
  const updateDisabled =
    !!selectedQuote && selectedQuote?.data?.status !== QUOTE_STATUS.INIT

  const updateQuoteFromForm = async (
    values: UpdateQuoteForm,
    initial = false,
    initialQuoteId: string | null = null
  ) => {
    if (
      !values.missionEnd ||
      // date invalide -> NaN
      isNaN(values.missionEnd.getTime())
    ) {
      values.missionEnd = null
    }

    const updateQuoteDto: UpdateQuoteDto = {
      missionStart:
        values.missionStart && values.missionStart.toString() !== 'Invalid Date'
          ? values.missionStart.toISOString()
          : new Date().toISOString(),
      missionEnd:
        values.missionEnd !== null ? values.missionEnd.toISOString() : null,
      services: initial
        ? servicesListToAutocompleteOptions(
            leadDetails?.announce.services || []
          ).map(item => item.value)
        : values.services.map(item => item.value),
      contractType: values.contractType as ContractType,
      estimationPeriod:
        estimateType === 'mensuel'
          ? 'MONTH'
          : estimateType === 'custom'
          ? 'FULL'
          : 'ONE_WEEK'
    }

    if (initial && initialQuoteId) {
      await updateQuoteMutation({ id: initialQuoteId, updateQuoteDto })

      return
    }

    if (selectedQuote?.id) {
      await updateQuoteMutation({ id: selectedQuote.id, updateQuoteDto })
    }
  }

  const formik = useFormik({
    initialValues: toUpdateQuoteForm(selectedQuote, leadDetails),
    onSubmit: async formData => {
      if (formData.missionEnd) {
        const startDate = new Date(formData.missionStart).getTime()

        const endDate = new Date(formData.missionEnd).getTime()

        if (startDate > endDate) {
          return
        }
      }

      await updateQuoteFromForm(formData)
      setDisplaySavedAlert(true)
    }
  })

  useEffect(() => {
    if (estimateType === 'hebdo') {
      formik.values.missionEnd = null
    }

    if (estimateType === 'mensuel') {
      formik.values.missionStart = new Date(DateTime.now().toISO())
      formik.values.missionEnd = new Date(
        DateTime.now().plus({ days: 30 }).toISO()
      )
    }
  }, [estimateType])

  const areDatesInvalid =
    formik.values.missionEnd &&
    new Date(formik.values.missionEnd || '').getTime() <
      new Date(formik.values.missionStart || '').getTime()

  useEffect(() => {
    formik.setValues(toUpdateQuoteForm(selectedQuote, leadDetails))

    if (selectedQuote?.data.estimationPeriod === 'ONE_WEEK') {
      setEstimateType('hebdo')
    }

    if (selectedQuote?.data.estimationPeriod === 'MONTH') {
      setEstimateType('mensuel')
    }

    if (selectedQuote?.data.estimationPeriod === 'CUSTOM') {
      setEstimateType('custom')
    }
  }, [selectedQuote, leadDetails])

  const estimateStartDate = DateTime.fromJSDate(
    formik.values.missionStart
  ).toFormat('yyyy-MM-dd')
  const estimateEndDate = getEstimateEndDate(
    formik.values.missionStart,
    formik.values.missionEnd,
    estimateType
  )

  const { data: estimatesData, refetch } = useRealScheduleWithEstimates(
    selectedQuote?.id,
    estimateStartDate,
    estimateEndDate,
    'quote'
  )

  useEffect(() => {
    refetch()
  }, [scheduleData, estimateStartDate, estimateEndDate, selectedQuote])

  return (
    (keycloak?.authenticated && keycloak.hasRealmRole('edit-quotes') && (
      <ThemeProvider theme={theme}>
        <Box sx={{ display: 'flex' }}>
          <Nav keycloak={keycloak} />
          <Box
            component="main"
            sx={{
              backgroundColor: currentTheme =>
                currentTheme.palette.mode === 'light'
                  ? currentTheme.palette.grey[100]
                  : currentTheme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto'
            }}
          >
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={displaySavedAlert}
              onClose={() => setDisplaySavedAlert(false)}
            >
              <Alert
                icon={<CheckIcon fontSize="inherit" />}
                severity="success"
                variant="filled"
                sx={{ width: '100%', color: 'white' }}
              >
                Devis sauvegardé
              </Alert>
            </Snackbar>
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h3" component="h1" sx={{ mb: 4 }}>
                  Editeur de Devis
                </Typography>
                <Grid container spacing={4}>
                  <Grid item md={6}>
                    <Stack spacing={2} sx={{ mb: 4 }}>
                      <Autocomplete
                        disablePortal
                        options={availableLeads ?? []}
                        sx={{ width: 300 }}
                        value={selectedLead || null}
                        onChange={(event, value) =>
                          value && handleLeadSelect(value.id)
                        }
                        renderInput={params => (
                          <TextField {...params} label="Sélectionner un lead" />
                        )}
                      />
                      {selectedLead &&
                        leadQuoteList &&
                        leadQuoteList?.length > 0 && (
                          <Autocomplete
                            disablePortal
                            options={leadQuoteList ?? []}
                            sx={{ width: 300 }}
                            value={selectedQuote || null}
                            onChange={(event, value) =>
                              value?.id && handleQuoteSelect(value.id)
                            }
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Sélectionner un devis"
                              />
                            )}
                          />
                        )}
                    </Stack>
                    {selectedQuote && (
                      <Grid container alignItems="center">
                        <form
                          onSubmit={formik.handleSubmit}
                          onReset={formik.handleReset}
                        >
                          <Grid item xs={12} marginTop={5}>
                            <Stack>
                              <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      onChange={() => setEstimateType('hebdo')}
                                      checked={estimateType === 'hebdo'}
                                      disabled={updateDisabled}
                                    />
                                  }
                                  label="Hebdomadaire"
                                />
                                <FormControlLabel
                                  control={
                                    <Switch
                                      onChange={() =>
                                        setEstimateType('mensuel')
                                      }
                                      checked={estimateType === 'mensuel'}
                                      disabled={updateDisabled}
                                    />
                                  }
                                  label="Mensuel"
                                />
                                <FormControlLabel
                                  control={
                                    <Switch
                                      onChange={() => setEstimateType('custom')}
                                      checked={estimateType === 'custom'}
                                      disabled={updateDisabled}
                                    />
                                  }
                                  label="Personnalisé"
                                />
                              </Stack>
                              {estimateType === 'custom' && (
                                <Stack
                                  direction="row"
                                  spacing={2}
                                  sx={{ mb: 2 }}
                                >
                                  <DatePicker
                                    name="missionStart"
                                    value={formik.values.missionStart}
                                    onChange={value => {
                                      formik.setFieldValue(
                                        'missionStart',
                                        value
                                      )
                                    }}
                                    label="Début de Mission"
                                    disablePast
                                    disabled={updateDisabled}
                                    maxDate={
                                      formik.values.missionEnd || undefined
                                    }
                                  />
                                  <DatePicker
                                    name="missionEnd"
                                    value={formik.values.missionEnd}
                                    onChange={value => {
                                      formik.setFieldValue('missionEnd', value)
                                    }}
                                    label="Fin de Mission"
                                    disablePast
                                    disabled={updateDisabled}
                                    minDate={
                                      formik.values.missionStart || undefined
                                    }
                                  />
                                </Stack>
                              )}
                            </Stack>
                            {areDatesInvalid && (
                              <Box marginBottom={2}>
                                <Typography color="#d44949">
                                  La date de fin est avant la date de début
                                </Typography>
                              </Box>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <Stack spacing={2} sx={{ mb: 3 }}>
                              <Autocomplete
                                disabled={updateDisabled}
                                multiple
                                disableCloseOnSelect
                                isOptionEqualToValue={(option, value) =>
                                  option.value === value.value
                                }
                                options={Object.entries(FAMILY_SERVICES_FR).map(
                                  ([key, value]) => ({
                                    label: value,
                                    value: key
                                  })
                                )}
                                getOptionLabel={option => option.label}
                                value={formik.values.services}
                                onChange={(e, value) => {
                                  formik.setFieldValue('services', value)
                                }}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label="Services"
                                    variant="outlined"
                                  />
                                )}
                              />
                              <Autocomplete
                                disabled={updateDisabled}
                                getOptionLabel={(option: ContractType) =>
                                  CONTRACT_TYPE_FR[option]
                                }
                                options={Object.values(ContractType)}
                                value={formik.values.contractType}
                                onChange={(e, value) => {
                                  formik.setFieldValue('contractType', value)
                                }}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label="Type de contrat"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Stack>
                          </Grid>
                        </form>
                      </Grid>
                    )}
                    {selectedQuote && estimatesData && (
                      <QuotePricing
                        pricingData={estimatesData}
                        multiplier={estimateType === 'mensuel' ? 4.33 : 1}
                      />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      container
                      direction="column"
                      justifyContent={'space-between'}
                      height="100%"
                    >
                      <Grid item md={6}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          spacing={2}
                        >
                          {selectedLead && (
                            <Grid item xs={6}>
                              <Button
                                startIcon={<AddIcon />}
                                sx={{ textAlign: 'left' }}
                                onClick={async () => {
                                  const quote =
                                    await newQuoteMutation.mutateAsync(
                                      selectedLead.id
                                    )

                                  await handleQuoteSelect(quote.id)
                                }}
                                variant="outlined"
                              >
                                Nouveau devis
                              </Button>
                            </Grid>
                          )}
                          {selectedLead && selectedQuote && (
                            <Grid item xs={6}>
                              <Button
                                variant="outlined"
                                color="primary"
                                size="medium"
                                sx={{ textAlign: 'left' }}
                                onClick={async () => {
                                  const newQuote =
                                    await duplicateQuoteMutation.mutateAsync(
                                      selectedQuote.id
                                    )

                                  navigate(
                                    `/devis/${leadId}/edit/${newQuote.id}`
                                  )
                                }}
                              >
                                Dupliquer le devis
                              </Button>
                            </Grid>
                          )}
                          {selectedQuote && (
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                size="medium"
                                onClick={openQuoteS3Url}
                                disabled={!selectedQuote?.data?.s3Url}
                              >
                                Télécharger le devis
                              </Button>
                            </Grid>
                          )}
                          {updateDisabled && (
                            <Grid item xs={12}>
                              <Alert severity="warning" sx={{ mb: 2 }}>
                                Ce devis a été généré il n'est plus editable
                              </Alert>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Stack
                          direction="row"
                          spacing={4}
                          sx={{ marginBottom: '44px' }}
                          justifyContent={'flex-start'}
                        >
                          {selectedQuote && (
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              disabled={
                                isUpdatePending ||
                                isSubmitting ||
                                updateDisabled ||
                                !!areDatesInvalid
                              }
                              onClick={() => formik.handleSubmit()}
                              style={{ maxHeight: '36px' }}
                            >
                              Enregistrer
                            </Button>
                          )}
                          <Stack style={{ position: 'relative' }}>
                            {selectedQuote?.data?.status ===
                              QUOTE_STATUS.INIT && (
                              <Button
                                color="secondary"
                                variant="contained"
                                disabled={
                                  isUpdatePending ||
                                  isSubmitting ||
                                  updateDisabled ||
                                  scheduleData?.length === 0
                                }
                                startIcon={<CheckIcon />}
                                onClick={async () => {
                                  try {
                                    setIsSumbitting(true)
                                    await updateQuoteFromForm(formik.values)
                                    await validateQuoteMutation.mutateAsync(
                                      selectedQuote.id
                                    )
                                  } catch (err) {
                                    window.alert(
                                      `Une erreur est survenue : ${validateQuoteMutation.error?.message}`
                                    )
                                  } finally {
                                    setIsSumbitting(false)
                                  }
                                }}
                              >
                                Générer le PDF
                              </Button>
                            )}
                            {selectedQuote && scheduleData?.length === 0 && (
                              <small
                                style={{
                                  position: 'absolute',
                                  bottom: -22
                                }}
                              >
                                Le devis n'a pas de créneau
                              </small>
                            )}
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {selectedQuote?.id && (
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Planning
                      editable={
                        selectedQuote.data?.status === QUOTE_STATUS.INIT
                      }
                      entityId={selectedQuote.id}
                      entityType="quote"
                      contractType={formik.values.contractType}
                      keycloak={keycloak}
                      isGenericWeek
                    />
                  </Grid>
                )}
              </Paper>
              <Copyright sx={{ pt: 4 }} />
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    )) || <Unauthorized />
  )
}
