import { Box } from '@mui/material'
import { Pricing } from 'utils/labels'

export const HDDetailsFooter = ({
  mission,
  pricings
}: {
  mission: any
  pricings: Pricing[]
}) => {
  const totalGlobalFees =
    pricings.reduce((acc, { globalFees }) => acc + globalFees, 0) / 100
  const hoursBilled = pricings.reduce(
    (acc, { hoursBilled }) => acc + hoursBilled,
    0
  )
  const cgTTC = pricings.reduce(
    (acc, { careGiverTaxExcludedAmount, careGiverVATAmount }) =>
      acc + careGiverTaxExcludedAmount + careGiverVATAmount,
    0
  )

  return (
    <>
      <Box textAlign={'center'} width="100%" fontSize={'1.4em'}>
        HEURES TOTALES EFFECTIVES : {hoursBilled} HEURES
      </Box>
      <Box textAlign={'center'} width="100%" fontSize={'1.4em'}>
        {mission.contractType === 'liberal' ? (
          <>
            Montant à payer TTC :{' '}
            {Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR'
            }).format(totalGlobalFees)}{' '}
          </>
        ) : (
          <>
            Salaire net, congés payés inclus :{' '}
            {Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR'
            }).format(cgTTC / 100)}{' '}
            <br />
            Taux horaire pour la déclaration Urssaf :{' '}
            {Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR'
            }).format(cgTTC / 100 / hoursBilled)}{' '}
            <br />
            <small>
              (Taux moyen incluant les majorations des dimanches et fériés)
            </small>
          </>
        )}
      </Box>
    </>
  )
}
